.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid rgb(22, 0, 117);
  border-right-color: rgb(0, 140, 190);
  animation: l2 1s infinite linear;
}
@keyframes l2 {
  to {
    transform: rotate(2turn);
  }
}
